;(function () {
   'use strict'

   angular.module('internalWebsiteApp').directive('bartenderTemplateTab', Directive)

   function Directive() {
      return {
         restrict: 'E',
         templateUrl: 'modules/bartender/tabs/templates/templates.html',
         controller: ['AzureAPI', 'alert', Controller],
         controllerAs: 'templateTab',
      }
   }

   function Controller(AzureAPI, alert) {
      var templateTab = this

      // --- Get Templates ----------------------------------------------------------
      templateTab.templates = []

      function getTemplates() {
         templateTab.templates = AzureAPI['bartender-template'].query({limit: 250})
      }
      // ------------------------------------------------------------------------

      // --- Create New Template ----------------------------------------------------
      templateTab.toggleNewForm = function () {
         templateTab.showNewForm = !templateTab.showNewForm
      }

      templateTab.createTemplate = function () {
         var newTemplate = AzureAPI['bartender-template'].create(templateTab.newTemplate)
         newTemplate.$promise
            .then(function () {
               templateTab.templates.unshift(newTemplate)
               templateTab.clearNewTemplate()
            })
            .catch(alert.catchNetwork('Bartender Templates'))
      }

      templateTab.clearNewTemplate = function () {
         templateTab.newTemplate = {}
         templateTab.showNewForm = false
      }
      // ------------------------------------------------------------------------

      // --- Manage Templates ---------------------------------------------------
      templateTab.editView = {}
      var stashedTemplate = {}

      templateTab.removeTemplate = function (template, index) {
         template
            .$delete()
            .then(function () {
               templateTab.templates.splice(index, 1)
            })
            .catch(alert.catchNetwork('Bartender Templates'))
      }

      templateTab.editTemplate = function (template) {
         stashedTemplate = angular.copy(template)
         templateTab.editView[template.id] = true
      }

      templateTab.cancelEditTemplate = function (template, index) {
         templateTab.templates[index] = stashedTemplate
         clearEditTemplate(template.id)
      }

      templateTab.saveTemplate = function (template) {
         template
            .$save()
            .then(function () {
               clearEditTemplate(template.id)
            })
            .catch(alert.catchNetwork('Template'))
      }

      function clearEditTemplate(templateId) {
         templateTab.editView[templateId] = false
         stashedTemplate = {}
      }
      // ------------------------------------------------------------------------

      // --- Initialization -----------------------------------------------------
      function init() {
         getTemplates()
         templateTab.clearNewTemplate()
      }
      init()
      // ------------------------------------------------------------------------
   }
})()
