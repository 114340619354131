;(function (angular, undefined) {
   'use strict'

   angular.module('internalWebsiteApp').factory('util', util)

   function util($q) {
      var _sortDirection = Object.freeze({
         ASC: 1,
         DESC: -1,
      })

      function getSortByPropertyComparer(property, sortDir) {
         if (sortDir === undefined) {
            sortDir = _sortDirection.ASC
         }

         return function (a, b) {
            if (a[property] > b[property]) {
               return sortDir
            }
            if (a[property] < b[property]) {
               return -sortDir
            }
            return 0
         }
      }

      function sortByProperty(property, array, descending) {
         var sortDir = descending ? _sortDirection.DESC : _sortDirection.ASC

         array.sort(getSortByPropertyComparer(property, sortDir))
         return array
      }

      function groupByProperty(arr, key) {
         //- src: https://stackoverflow.com/a/47385953
         return arr.reduce(function (hash, obj) {
            if (obj[key] === undefined) {
               return hash
            }
            return Object.assign(hash, {[obj[key]]: (hash[obj[key]] || []).concat(obj)})
         }, {})
      }

      function stripCountryFromPhone(num) {
         if (num && num.startsWith('+1 ')) {
            return num.slice(3)
         } else {
            return num
         }
      }

      function addCountryToPhone(num) {
         if (num && num.startsWith('+1 ')) {
            return num
         } else {
            return '+1 ' + num
         }
      }

      function cleanPhone(num) {
         var phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/
         if (num && !phoneRegex.test(num) && !num.startsWith('+1 ')) {
            num = num.replace(/[^0-9]/g, '') // strip non digit chars
            return num.slice(0, 11) // only use the first ten digits
         } else {
            return num
         }
      }

      function cleanItem(item) {
         if (item) {
            delete item.$promise
            delete item.$resolved
         }
         return item
      }

      function first(items) {
         return items[0]
      }

      function mapToProp(items, prop) {
         return items.map(function (item) {
            return item[prop]
         })
      }

      function filterUndefined(items) {
         return items.filter(function (item) {
            return item !== undefined
         })
      }

      function getParamsFromUrlString(urlString) {
         // Returns an object of param/value pairs.
         if (!urlString || urlString.indexOf('?') === -1) {
            return
         }
         return urlString
            .split('?')[1]
            .split('&')
            .reduce(function (acc, item) {
               var keyValue = item.split('=')
               acc[keyValue[0]] = keyValue[1]
               return acc
            }, {})
      }

      function makePascalCase(str) {
         if (!str) {
            return
         }
         return str
            .replace(/[\W_]/g, ' ') //convert all hyphens to spaces
            .toLowerCase()
            .split(' ')
            .map(function (s) {
               return s.charAt(0).toUpperCase() + s.substring(1)
            })
            .join('')
      }

      function makeCamelCase(str) {
         if (!str) {
            return
         }
         var pascalString = makePascalCase(str)
         return pascalString.charAt(0).toLowerCase() + pascalString.substr(1)
      }

      function removeById(items, id) {
         for (var i = 0; i < items.length; i++) {
            if (items[i].id === id) {
               items.splice(i, 1)
               break
            }
         }
         return items
      }

      function findByPropertyValue(items, property, propertyValue) {
         return items.find(function (item) {
            return item && item[property] === propertyValue
         })
      }

      function findById(items, id) {
         return findByPropertyValue(items, 'id', id)
      }

      function findIndexByPropertyValue(items, property, propertyValue) {
         return items.findIndex(function (item) {
            return item && item[property] === propertyValue
         })
      }

      function findIndexById(items, id) {
         return findIndexByPropertyValue(items, 'id', id)
      }

      function getPagesUntilEnd(getPageFunction, start, limit, resultsArray) {
         resultsArray = resultsArray || []
         return getPageFunction(start, limit).then(function (items) {
            resultsArray.push.apply(resultsArray, items)
            if (items.length < limit) {
               return resultsArray
            }
            return getPagesUntilEnd(getPageFunction, start + limit, limit, resultsArray)
         })
      }

      // src: https://gist.github.com/sqren/cad155d622045d1fe5f98ac09cd4f92b
      function batchRequests(items, fn, options) {
         var results = []
         var index = options.batchSize - 1
         function getNextItem() {
            index++
            if (items.length > index) {
               var nextItem = items[index]
               return getCurrentItem(nextItem)
            }
         }
         function getCurrentItem(item) {
            // Starting
            return fn(item)
               .then(function (result) {
                  // Success
                  results.push(result)
                  return getNextItem()
               })
               .catch(function () {
                  return options.retry ? getCurrentItem(item) : getNextItem()
               })
         }
         var promises = items.slice(0, options.batchSize).map(function (item) {
            return getCurrentItem(item)
         })
         return $q.all(promises).then(function () {
            return results
         })
      }

      // The first argument to asyncRetryOnce should be an asynchronous function f you
      // want to retry. f should either return a promise or an object with a '$promise'
      // property. The remaining arguments to asyncRetryOnce will be passed into f.
      // For example:
      //    retryOnce(testAsyncFunction, 1, 2)
      // will call:
      //    testAsyncFunction(1, 2)
      // This will retry on failure, unless the error.status is less than 500. In those
      // cases retrying will probably not do any good.
      function asyncRetryOnce() {
         var thisArg = this
         var f = arguments[0]

         if (typeof f !== 'function') {
            return
         }

         var args = Array.prototype.slice.call(arguments, 1)
         var firstResult = f.apply(thisArg, args)
         if (!firstResult) {
            return
         }

         return (firstResult.$promise || firstResult).catch(function (error) {
            if (0 < error.status && error.status < 500) {
               return $q.reject(error)
            }
            var retryResult = f.apply(thisArg, args)
            return retryResult.$promise || retryResult
         })
      }

      // Like `allSettled` (returns an array or result objects, with state either "fulfilled" or "rejected")
      // except that it:
      //   - requires the passed `promiseFunctions` functions to have "pre bound" parameters (e.g. `saveStopChanges.bind(null, stop);`)
      //   - executes sequentially (one after the other, each waiting for the previous to resolve or reject)
      // Adapted from answers on https://stackoverflow.com/questions/24586110/resolve-promises-one-after-another-i-e-in-sequence
      function promiseSequence(promiseFunctions) {
         var results = []

         // Initial empty promise.
         var sequence = $q.resolve()

         // Iterate through the items, each time adding a promise to the
         // end of the `sequence` promise.
         promiseFunctions.forEach(function (promiseFunction) {
            // Add a computation onto the sequence
            sequence = sequence.then(function () {
               return promiseFunction()
                  .then(function (result) {
                     results.push({
                        state: 'fulfilled',
                        value: result,
                     })
                  })
                  .catch(function (error) {
                     console.error(error)
                     results.push({
                        state: 'rejected',
                        reason: error,
                     })
                  })
            })
         })

         // Resolves after all promises in the sequence have either resolved or rejected
         return sequence.then(function () {
            return results
         })
      }

      //================================================================================

      return {
         sortByProperty: sortByProperty,
         groupByProperty: groupByProperty,
         stripCountryFromPhone: stripCountryFromPhone,
         addCountryToPhone: addCountryToPhone,
         cleanPhone: cleanPhone,
         cleanItem: cleanItem,
         first: first,
         getParamsFromUrlString: getParamsFromUrlString,
         makePascalCase: makePascalCase,
         makeCamelCase: makeCamelCase,
         mapToProp: mapToProp,
         filterUndefined: filterUndefined,
         removeById: removeById,
         findByPropertyValue: findByPropertyValue,
         findById: findById,
         findIndexByPropertyValue: findIndexByPropertyValue,
         findIndexById: findIndexById,
         getPagesUntilEnd: getPagesUntilEnd,
         batchRequests: batchRequests,
         asyncRetryOnce: asyncRetryOnce,
         promiseSequence: promiseSequence,
      }
   }
})(angular)
