;(function (angular, Sentry) {
   'use strict'

   angular.module('azure.exceptionHandler', []).config(exceptionHandlerConfig)

   function exceptionHandlerConfig($provide) {
      if (!Sentry) {
         return
      }
      // Reporting Exceptions in Sentry
      // Adapted from https://github.com/getsentry/sentry-javascript/blob/master/packages/integrations/src/angular.ts
      var angularPattern = /^\[((?:[$a-zA-Z0-9]+:)?(?:[$a-zA-Z0-9]+))\] (.*?)\n?(\S+)$/
      Sentry.configureScope(function (scope) {
         scope.addEventProcessor(function (event) {
            var ex = event.exception && event.exception.values && event.exception.values[0]

            if (ex) {
               var matches = angularPattern.exec(ex.value || '')

               if (matches) {
                  // This type now becomes something like: $rootScope:inprog
                  ex.type = matches[1]
                  ex.value = matches[2]
                  event.message = ex.type + ': ' + ex.value
                  // auto set a new tag specifically for the angular error url
                  if (!event.extra) {
                     event.extra = {}
                  }
                  event.extra.angularDocs = matches[3].substr(0, 250)
               }
            }

            return event
         })
      })

      $provide.decorator('$exceptionHandler', function ($delegate) {
         return function (exception, cause) {
            if (Sentry) {
               Sentry.withScope(function (scope) {
                  if (cause) {
                     scope.setExtra('cause', cause)
                  }
                  Sentry.captureException(exception)
               })
            }
            $delegate(exception, cause)
         }
      })
   }
})(angular, window.Sentry)
