;(function () {
   'use strict'

   angular
      .module('internalWebsiteApp')
      .controller('ModalTruckCarrierFormController', function (
         $scope,
         $uibModalInstance,
         logisticsData,
         alertService,
         carrierTypeOptions,
         logisticsRootState,
         currentTrip,
         truckloadId,
         setBusyTrue,
         setBusyFalse,
         existingCarrierData,
         onSubmitSuccess
      ) {
         var currentTruckload = currentTrip.truckloadsById[truckloadId]

         function truckPeopleOptionsMapper(optionsObj) {
            return optionsObj.map(function (entry) {
               return {
                  label: entry.name,
                  value: entry.id,
               }
            })
         }

         $scope.isFieldDisabled = function (fieldKey) {
            return currentTruckload && currentTruckload.disabledCarrierInfoFormFields.includes(fieldKey)
         }

         $scope.truckCarrierFields = {
            truckDriverPersonId: {
               label: 'Driver',
               type: 'select',
               isRequired: true,
               options: truckPeopleOptionsMapper(logisticsRootState.truckloadPeopleByType.truckDrivers),
            },
            carrierTypeId: {
               label: 'Type',
               type: 'select',
               isRequired: true,
               onChange: function () {
                  if ($scope.truckCarrierFormValues.carrierContractor !== 'contractor') {
                     delete $scope.truckCarrierFormValues.carrierContractor
                     delete $scope.truckCarrierFormValues.carrierContractorMileage
                  }
               },
               options: angular.extend([], carrierTypeOptions),
            },
            driveAzureMileage: {
               label: 'Drive Azure Mileage',
               type: 'int',
               showIf: 'truckCarrierFormValues.carrierTypeId === 1',
               isRequired: false,
               inputSuffix: 'miles',
            },
            carrierContractorPersonId: {
               label: 'Contractor',
               type: 'select',
               showIf: 'truckCarrierFormValues.carrierTypeId === 3',
               isRequired: true,
               options: truckPeopleOptionsMapper(logisticsRootState.truckloadPeopleByType.carrierContractors),
            },
            carrierContractorMileage: {
               label: 'Contractor Mileage',
               type: 'int',
               showIf: 'truckCarrierFormValues.carrierTypeId === 3',
               isRequired: false,
               inputSuffix: 'miles',
            },
            isCarrierShuttle: {
               label: 'Is Shuttle',
               type: 'toggle',
               onChange: function () {
                  if (!$scope.truckCarrierFormValues.isCarrierShuttle) {
                     delete $scope.truckCarrierFormValues.carrierShuttleType
                     delete $scope.truckCarrierFormValues.carrierShuttleQuote
                     delete $scope.truckCarrierFormValues.carrierShuttleMileage
                  }
               },
               labelIfTrue: 'Yes',
               labelIfFalse: 'No',
               default: false,
            },
            carrierShuttlePersonId: {
               label: 'Shuttle',
               type: 'select',
               showIf: '!!truckCarrierFormValues.isCarrierShuttle',
               isRequired: true,
               options: truckPeopleOptionsMapper(logisticsRootState.truckloadPeopleByType.carrierShuttles),
            },
            carrierShuttleQuote: {
               // entry in dollars of the shuttle cost
               label: 'Shuttle Quote',
               type: 'dollar',
               showIf: '!!truckCarrierFormValues.isCarrierShuttle',
               isRequired: true,
            },
            carrierShuttleMileage: {
               label: 'Shuttle Mileage',
               type: 'int',
               showIf: '!!truckCarrierFormValues.isCarrierShuttle',
               isRequired: false,
               inputSuffix: 'miles',
            },
         }

         $scope.truckCarrierFormValues = existingCarrierData ? angular.extend({}, existingCarrierData) : {}

         Object.entries($scope.truckCarrierFields).forEach(function ([key, value]) {
            if (!existingCarrierData || (!Object.hasOwn(existingCarrierData, key) && Object.hasOwn(value, 'default'))) {
               $scope.truckCarrierFormValues[key] = value.default
            }
         })

         $scope.submitTruckCarrierForm = function (truckCarrierForm) {
            if (truckCarrierForm.$invalid) {
               return
            }

            $uibModalInstance.close()

            setBusyTrue('Updating truck carrier info')

            logisticsData
               .updateTruckload(
                  angular.extend(
                     {
                        id: currentTruckload.id,
                     },
                     $scope.truckCarrierFormValues
                  )
               )
               .then(function (updatedTruckload) {
                  onSubmitSuccess(updatedTruckload)
               })
               .catch(function (error) {
                  console.error(error)
                  alertService.errorMessage('Error updating truck carrier info')
               })
               .finally(setBusyFalse)
         }
      })
})()
