;(function () {
   'use strict'

   angular.module('internalWebsiteApp').controller('DropMembershipCtrl', DropMembershipController)

   function DropMembershipController($scope, $q, AzureAPI, config, util, alertService) {
      var _dmState = {
         pendingMembershipsLoading: true,
         pendingMembershipsByDrop: [],
         isRefreshing: false,
         sortBy: 'drop.nextCutoff',
         sortReverse: false,
      }
      $scope.dmState = _dmState

      //================================================================================

      function getUnconfirmedTripsByDropId(dropId) {
         // Leaving 'cutoff-after' set to three days ago to facilitate development
         // servers where trip confirmations may not happen regularly.
         // If this is a problem in production, it can be altered or removed.
         var date = new Date()
         date.setDate(date.getDate() - 3)
         return util.asyncRetryOnce(AzureAPI.trip.query, {
            drop: dropId,
            'cutoff-after': date,
            confirmed: false,
            start: -10,
         })
      }

      function getPendingMemberships() {
         function getPending(start, limit) {
            return AzureAPI['drop-membership'].query({
               start: start,
               pending: true,
               active: true,
               inline: 'customer.email,drop',
               limit: limit,
            }).$promise
         }

         return util.getPagesUntilEnd(getPending, 0, config.apiLimit)
      }

      function getCoordinatorById(id) {
         var coordinator = util.asyncRetryOnce(AzureAPI.person.get, {
            id: id,
            inline: 'email,is-drop-coordinator',
         })
         return $q.resolve(coordinator)
      }

      function updateMembershipById(id, data) {
         return util.asyncRetryOnce(AzureAPI['drop-membership'].save, {id: id}, data)
      }

      function deleteMembershipById(id) {
         return util.asyncRetryOnce(AzureAPI['drop-membership'].delete, {id: id})
      }

      function daysAgo(daysAgoDate) {
         var now = new Date()
         var requestedDate = new Date(daysAgoDate)
         var differenceInTime = now.getTime() - requestedDate.getTime()
         var differenceInDays = differenceInTime / (1000 * 3600 * 24)
         return Math.abs(Math.floor(differenceInDays))
      }

      //================================================================================
      // View functions
      //================================================================================

      function toPendingMembershipView(pm) {
         var promises = [getUnconfirmedTripsByDropId(pm.drop.id), getCoordinatorById(pm.drop.coordinators[0])]

         return $q.all(promises).then(function (results) {
            var trips = results[0]
            var coordinator = results[1]

            var pmView = {
               busy: false,
            }
            pmView.membership = pm
            pmView.membership.drop.trips = util.sortByProperty('cutoff', trips)
            pmView.membership.drop.nextCutoff = pm.drop.trips.length && pm.drop.trips[0].cutoff
            pmView.requestAge = daysAgo(pm.created)
            pmView.membership.drop.coordinator = coordinator

            return pmView
         })
      }

      //================================================================================
      // Scope functions
      //================================================================================

      $scope.togglePendingMembershipUi = function (dropId) {
         _dmState.pendingMembershipsByDrop.forEach(function (dropView) {
            if (dropView.id === dropId) {
               dropView.isUiExpanded = !dropView.isUiExpanded
            } else {
               dropView.isUiExpanded = false
            }
         })
      }

      $scope.sortDrops = function (key) {
         if (key === _dmState.sortBy) {
            _dmState.sortReverse = !_dmState.sortReverse
         } else {
            _dmState.sortBy = key
            _dmState.sortReverse = false
         }
      }

      $scope.acceptRequest = function (pmView) {
         pmView.busy = true
         pmView.membership.pending = false
         return updateMembershipById(pmView.membership.id, pmView.membership)
            .then(function () {
               pmView.isApproved = true
               pmView.busy = false
               alertService.successMessage('Membership approved')
            })
            .catch(function (err) {
               pmView.busy = false
               console.error(err)
               alertService.errorMessage('There was an error approving the request. Error logged to console.')
            })
      }

      $scope.rejectRequest = function (pmView) {
         pmView.busy = true
         return deleteMembershipById(pmView.membership.id)
            .then(function () {
               pmView.isRejected = true
               pmView.busy = false
               alertService.info({
                  message: 'Membership rejected',
                  autoClose: true,
                  closeBtn: false,
               })
            })
            .catch(function (err) {
               pmView.busy = false
               console.error(err)
               alertService.errorMessage('There was an error rejecting the request. Error logged to console.')
            })
      }

      $scope.refresh = function () {
         _dmState.pendingMembershipsByDrop = []
         _dmState.pendingMembershipsLoading = true
         _dmState.isRefreshing = true
         loadPendingMemberships().finally(function () {
            _dmState.isRefreshing = false
         })
      }

      $scope.countAllPendingMemberships = function () {
         var dropPendingCounts = _dmState.pendingMembershipsByDrop.map($scope.countDropsPendingMemberships)

         return dropPendingCounts.reduce(function (a, b) {
            return a + b
         }, 0)
      }

      $scope.countDropsPendingMemberships = function (dropView) {
         var pending = dropView.pendingMembershipViews.filter(function (pmView) {
            return !pmView.isApproved && !pmView.isRejected
         })

         return pending.length
      }

      //================================================================================
      // Init handlers
      //================================================================================

      function loadPendingMemberships() {
         return getPendingMemberships().then(function (pendingMemberships) {
            return $q.map(pendingMemberships, toPendingMembershipView).then(function (pmViews) {
               pmViews.forEach(function (pmView) {
                  var alreadyCreated = util.findById(_dmState.pendingMembershipsByDrop, pmView.membership.drop.id)
                  if (alreadyCreated) {
                     alreadyCreated.pendingMembershipViews.push(pmView)
                  } else {
                     _dmState.pendingMembershipsByDrop.push({
                        id: pmView.membership.drop.id,
                        drop: pmView.membership.drop,
                        pendingMembershipViews: [pmView],
                     })
                  }
               })

               // Order by request date
               _dmState.pendingMembershipsByDrop.forEach(function (dropView) {
                  dropView.oldestRequestDate = dropView.pendingMembershipViews[0].membership.created
                  dropView.oldestRequestDaysAgo = daysAgo(dropView.oldestRequestDate)
               })

               _dmState.pendingMembershipsLoading = false
            })
         })
      }

      //================================================================================
      // Init
      //================================================================================

      function init() {
         loadPendingMemberships()
      }

      init()
   }
})()
