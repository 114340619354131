;(function () {
   'use strict'
   angular
      .module('internalWebsiteApp')
      .directive('addCategory', function () {
         var directive = {
            restrict: 'E',
            templateUrl: 'modules/categories/categories-add.html',
            scope: {
               parent: '=',
               successAction: '=success',
               cancelAction: '=cancel',
            },
            controller: 'AddCategoryCtrl',
         }
         return directive
      })

      .controller('AddCategoryCtrl', function ($scope, AzureAPI, Upload) {
         var parent = $scope.parent
         if (!parent.id) {
            parent = {
               id: null,
               primary: true,
               active: true,
            } // add a dummy parent for the add form to use
         }

         $scope.clearCategoryToAdd = function () {
            $scope.categoryToAdd = {
               canBePrimary: parent.primary,
               parent: parent.id,
               active: parent.active,
               canBeActive: parent.active,
            }
         }
         $scope.clearCategoryToAdd()

         $scope.createCategory = function (valid) {
            if (!valid) {
               return
            }
            if ($scope.categoryToAdd.keywords) {
               $scope.categoryToAdd.keywords = $scope.categoryToAdd.keywords.split(/\s*,\s*/)
            } else {
               $scope.categoryToAdd.keywords = []
            }
            if ($scope.categoryToAdd.newimage instanceof Blob) {
               Upload.base64DataUrl($scope.categoryToAdd.newimage).then(function (dataurl) {
                  $scope.categoryToAdd.image = dataurl.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                  delete $scope.categoryToAdd.newImage
                  saveCategory($scope.categoryToAdd)
               })
            } else {
               saveCategory($scope.categoryToAdd)
            }
         }

         function saveCategory(category) {
            AzureAPI.category.create(category).$promise.then(function (resp) {
               $scope.successAction(resp, $scope.parent)
               $scope.parent.displayAddCategory = false
               $scope.clearCategoryToAdd()
            })
         }

         $scope.cancel = function () {
            $scope.clearCategoryToAdd()
            $scope.cancelAction($scope.parent)
            $scope.parent.displayAddCategory = false
         }
      })
})()
