'use strict'

/**
 * @ngdoc directive
 * @name internalWebsiteApp.directive:alerts
 * @description
 * # alerts
 */
angular.module('internalWebsiteApp').directive('alerts', function () {
   return {
      restrict: 'E',
      templateUrl: 'modules/alerts/alerts.html',
   }
})
