;(function () {
   'use strict'

   angular
      .module('internalWebsiteApp')
      .controller('ProductAuditSizeCtrl', function ($location, $http, AzureAPI, alert) {
         var pas = this
         pas.packRegEx = /^[0-9]+([x]?[0-9,.]+)*$/i

         pas.unitsList = [
            'liter',
            'milliliter',
            'gallon',
            'quart',
            'pint',
            'cup',
            'fluid-ounce',
            'Mass',
            'gram',
            'kilogram',
            'ounce',
            'pound',
            'yard',
         ]

         function resetPage() {
            pas.page = {
               current: 1,
               total: 0,
               size: 7,
               offset: 0,
               listSize: 25,
            }
         }
         resetPage()

         function getPieces() {
            var opts = {
               start: pas.page.current * pas.page.listSize - pas.page.listSize,
               audited: pas.filterAudited,
               search: pas.searchTerm,
               'packaged-product': pas.packagedProduct ? pas.packagedProduct.toUpperCase() : null,
            }
            pas.products = []
            AzureAPI['audit-product'].query(opts).$promise.then(function (resp) {
               resp.data.forEach(function (meta) {
                  processPackages(meta)
               })
               pas.products = resp.data
               pas.page.total = resp.resource.count
            })
         }

         function processPackages(data) {
            data.packaging.forEach(function (piece) {
               if (pas.unitsList.indexOf(piece.unit) === -1) {
                  piece.unit_other = true
               }
               piece.oldSize = piece.size
            })
         }

         pas.updateSize = function (pack) {
            pack.size = pack.packs.split('x').join(' x ') + ' ' + pack.unit
         }

         pas.showAllPieces = function (product) {
            AzureAPI['audit-product'].get(product).$promise.then(function (resp) {
               processPackages(resp)
               if (pas.products && pas.products.length) {
                  pas.products.forEach(function (p, i) {
                     if (resp.id == p.id) {
                        pas.products[i] = resp
                     }
                  })
               } else {
                  pas.products = [resp]
               }
            })
         }

         pas.markAudited = function (pack) {
            if (pas.packRegEx.test(pack.packs)) {
               AzureAPI['audit-product'].save(pack).$promise.then(
                  function (resp) {
                     pack.audited = resp.audited
                     pack.name = resp.name
                  },
                  function (resp) {
                     if (resp.status == 400) {
                        alert(resp.data.message)
                     }
                  }
               )
            } else {
               alert.add(
                  'danger',
                  'There was a problem with the value for packs. Please double check your input and try again.'
               )
            }
         }

         pas.markUnAudited = function (pack) {
            pack['clear-audited'] = 'true'
            AzureAPI['audit-product'].save(pack).$promise.then(function (resp) {
               pack.audited = resp.audited
               pack.name = resp.name
               delete pack['clear-audited']
            })
         }

         pas.pageChanged = function () {
            getPieces()
         }

         pas.loadPieces = function (audited) {
            $location.search({
               audited: audited,
            })
         }

         pas.search = function () {
            var queryParams = {}
            if (isId(pas.query)) {
               queryParams.id = pas.query
            } else if (isCode(pas.query)) {
               queryParams.code = pas.query
            } else {
               queryParams.search = pas.query
            }
            $location.search(queryParams)
         }

         function isId(term) {
            // ids are all numbers. This will return true if the given term is composed only of numbers.
            return !isNaN(term)
         }

         function isCode(term) {
            // codes consist of two alphabetic characters followed by a series of numbers
            var chars = term.substr(0, 2)
            var nums = term.substr(2)
            return isNaN(chars) && !isNaN(nums)
         }

         function initialize() {
            var params = $location.search()
            pas.id = params.id
            pas.searchTerm = params.search
            pas.packagedProduct = params.code
            pas.filterAudited = !!params.audited
            pas.query = pas.searchTerm || pas.packagedProduct || pas.id
            if (pas.id) {
               pas.showAllPieces({
                  id: parseInt(pas.id),
               })
            } else {
               if (pas.packagedProduct || pas.searchTerm || params.audited == 'all') {
                  pas.filterAudited = null
               }
               getPieces()
            }
         }
         initialize()
      })
})()
