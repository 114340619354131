;(function (angular) {
   'use strict'

   angular.module('internalWebsiteApp').factory('logisticsData', logisticsData)

   function logisticsData(AzureAPI, $http, ENV, config, util) {
      //================================================================================
      // Current user
      //================================================================================
      function getCurrentUser() {
         var options = {
            inline: 'email',
         }
         return util.asyncRetryOnce(AzureAPI.person.get, options).then(util.cleanItem)
      }

      //================================================================================
      // Routes
      //================================================================================

      function getAllRoutes() {
         function getRoutes(start, limit) {
            return AzureAPI.route.query({start: start, limit: limit}).$promise
         }
         return util.getPagesUntilEnd(getRoutes, 0, config.apiLimit)
      }

      function updateRoute(route) {
         return util.asyncRetryOnce(AzureAPI.route.save, route)
      }

      //================================================================================
      // Trips
      //================================================================================

      function getTrips(options) {
         var defaultOptions = {
            limit: config.apiLimit,
         }
         var requestOptions = angular.extend({}, defaultOptions, options)
         return util.asyncRetryOnce(AzureAPI.trip.query, requestOptions)
      }

      function getFurthestOutTrip(routeName) {
         return getTrips({
            route: routeName,
            limit: 1,
         }).then(util.first)
      }

      function getRoutesEarliestFutureTrip(routeName) {
         var options = {
            route: routeName,
            'cutoff-after': new Date(),
            confirmed: false,
            start: -1,
            limit: 1,
         }
         return getTrips(options)
            .then(util.cleanItem)
            .then(function (trips) {
               return trips[0]
            })
      }

      function updateTrip(trip) {
         return util.asyncRetryOnce(AzureAPI.trip.save, trip)
      }

      function deleteTrip(tripId) {
         return util.asyncRetryOnce(AzureAPI.trip.delete, {
            id: tripId,
         })
      }

      function createTrip(trip) {
         return util.asyncRetryOnce(AzureAPI.trip.create, trip)
      }

      //================================================================================
      // Truckloads
      //================================================================================

      function getTruckloadById(truckloadId) {
         return util.asyncRetryOnce(AzureAPI.truckload.get, {id: truckloadId}).then(util.cleanItem)
      }

      function updateTruckload(truckload) {
         return util.asyncRetryOnce(AzureAPI.truckload.save, truckload)
      }

      function createCarrierPos(truckloadId) {
         // Note that I couldn't figure out how to use `AzureAPI` here without modifying it (which seems like overkill)
         return $http({
            method: 'POST',
            url: ENV.apiEndpoint + '/truckload/generate-carrier-pos/' + truckloadId,
            withCredentials: true,
         }).then(function (response) {
            return response.data
         })
      }

      //================================================================================
      // Trip Stops
      //================================================================================

      function getAllTripStops(tripId) {
         function getTripStops(start, limit) {
            return AzureAPI.stop.query({
               trip: tripId,
               start: start,
               limit: limit,
               inline: 'truckload',
            }).$promise
         }
         return util.getPagesUntilEnd(getTripStops, 0, config.apiLimit)
      }

      function getTripsEarliestDrop(tripId) {
         // returns a trip's earliest stop that's a drop (not a pickup)
         function getTripStop(start) {
            return AzureAPI.stop.query({
               trip: tripId,
               start: start,
               limit: 1,
            }).$promise
         }

         var start = 0
         function retryUntilDrop() {
            return getTripStop(start).then(function (stops) {
               if (!stops[0]) {
                  return
               }
               if (stops[0].dropId) {
                  return stops[0]
               }
               // If the stop doesn't have a `dropId` then it's a pickup.
               // Retry until we get the earliest drop.
               start++
               return retryUntilDrop()
            })
         }

         return retryUntilDrop()
      }

      function deleteStop(options) {
         return util.asyncRetryOnce(AzureAPI.stop.delete, options)
      }

      function createStop(options, data) {
         return util.asyncRetryOnce(AzureAPI.stop.create, options, data)
      }

      function updateStop(options, data) {
         return util.asyncRetryOnce(AzureAPI.stop.update, options, data)
      }

      //================================================================================
      // Drops
      //================================================================================

      function getDropById(dropId) {
         return util.asyncRetryOnce(AzureAPI.drop.get, {id: dropId}).then(util.cleanItem)
      }

      function getAllRouteDrops(route, options) {
         function getDrops(start, limit) {
            var defaultOptions = {
               route: route.name,
               limit: limit,
               start: start,
            }
            var requestOptions = angular.extend({}, defaultOptions, options)
            return AzureAPI.drop.query(requestOptions).$promise
         }
         return util.getPagesUntilEnd(getDrops, 0, config.apiLimit)
      }

      function getAllRouteDropsActive(route) {
         return getAllRouteDrops(route, {active: true})
      }

      function getAllRouteDropsInactive(route) {
         return getAllRouteDrops(route, {active: false})
      }

      //================================================================================
      // Pickups
      //================================================================================

      function getAllRoutePickups(route) {
         function getPickups(start, limit) {
            return AzureAPI.pickup.query({
               route: route.name,
               start: start,
               limit: limit,
            }).$promise
         }
         return util.getPagesUntilEnd(getPickups, 0, config.apiLimit)
      }

      //================================================================================
      // People (e.g. Truck Drivers, Carrier Contractors, etc.)
      //================================================================================

      function getPerson(customerId) {
         if (customerId) {
            return AzureAPI.person.get({
               id: customerId,
            }).$promise
         } else {
            return new Promise((resolve, reject) => reject('Must pass a valid person id to getPerson.'))
         }
      }

      function getTruckloadRelatedPeople() {
         return AzureAPI.person
            .query({
               limit: config.apiLimit,
               customerType: 'truck-driver,carrier-contractor,carrier-shuttle',
            })
            .$promise.then(util.cleanItem)
            .then(function (people) {
               return util.sortByProperty('name', people)
            })
      }

      //================================================================================

      return {
         getCurrentUser: getCurrentUser,
         // People (e.g. Truck Drivers, etc.)
         getPerson: getPerson,
         getTruckloadRelatedPeople: getTruckloadRelatedPeople,
         // Routes
         getAllRoutes: getAllRoutes,
         updateRoute: updateRoute,
         // Trips
         getTrips: getTrips,
         getFurthestOutTrip: getFurthestOutTrip,
         getRoutesEarliestFutureTrip: getRoutesEarliestFutureTrip,
         updateTrip: updateTrip,
         deleteTrip: deleteTrip,
         createTrip: createTrip,
         // Truckloads
         getTruckloadById: getTruckloadById,
         updateTruckload: updateTruckload,
         createCarrierPos: createCarrierPos,
         // Trip Stops
         getAllTripStops: getAllTripStops,
         getTripsEarliestDrop: getTripsEarliestDrop,
         deleteStop: deleteStop,
         createStop: createStop,
         updateStop: updateStop,
         // Drops
         getDropById: getDropById,
         getAllRouteDrops: getAllRouteDrops,
         getAllRouteDropsActive: getAllRouteDropsActive,
         getAllRouteDropsInactive: getAllRouteDropsInactive,
         // Pickups
         getAllRoutePickups: getAllRoutePickups,
      }
   }
})(angular)
