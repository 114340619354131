;(function () {
   'use strict'
   angular.module('internalWebsiteApp').directive('dropManager', Directive)

   function Directive() {
      return {
         restrict: 'E',
         templateUrl: 'modules/logistics/Directives/dropManager/dropManager.html',
         controller: Controller,
      }
   }

   function Controller($rootScope, $scope) {
      var _logisticsRootState = $scope.logisticsRootState
      var _selectUiState = $scope.selectUiState
      var _selectUiHandlers = $scope.selectUiHandlers
      var _localState = {
         hideInactive: true,
         dropFilterObject: {
            active: true,
         },
         tableState: {
            sortedBy: 'name',
            sortedReverse: false,
         },
      }
      $scope.localState = _localState

      //================================================================================
      // Scope functions
      //================================================================================

      $scope.handleColumnHeaderClick = function (key) {
         function sortList(key) {
            if (key === _localState.tableState.sortedBy) {
               _localState.tableState.sortedReverse = !_localState.tableState.sortedReverse
            } else {
               _localState.tableState.sortedBy = key
               _localState.tableState.sortedReverse = false
            }
         }

         $rootScope.blur()
         sortList(key)
      }

      $scope.toggleHideInactive = function () {
         // Hide visible inactive drops
         if (_localState.hideInactive) {
            _localState.dropFilterObject.active = true

            // Unselect all inactive drops
            _selectUiState.selectedList = _selectUiState.selectedList.filter(function (drop) {
               return drop.active
            })
            _logisticsRootState.route.drops.forEach(function (drop) {
               if (!drop.active) {
                  drop.selectedInUi = false
               }
            })

            // Maybe check the "all selected" checkbox
            if (_selectUiState.selectedList.length === _logisticsRootState.route.dropsActive.length) {
               _selectUiState.allVisibleSelected = true
            }
         }
         // Make hidden inactive drops visible
         else {
            delete _localState.dropFilterObject.active
            // Ensure that the "all selected" checkbox is not checked
            _selectUiState.allVisibleSelected = false
         }
      }

      //================================================================================
      // Events & Watchers
      //================================================================================

      $scope.$on('paramsChanged', function () {
         // Since we're caching the drops in memory we need to ensure that
         // none are selected when they come into view.
         _selectUiHandlers.unselectAll()
      })
   }
})()
