;(function () {
   'use strict'

   angular.module('internalWebsiteApp').controller('RecallCtrl', recallController)

   function recallController($scope, $routeParams, $q, AzureAPI, alert, util, config) {
      $scope.packagedProduct = $routeParams['packaged-product']
      $scope.purchase = $routeParams.purchase

      $scope.clearStatus = function () {
         $scope.status = null
      }

      var _batchSize = 100

      //================================================================================
      // Report
      //================================================================================

      $scope.submitReportRequest = function () {
         if (!$scope.packagedProduct || !$scope.purchase) {
            return
         }

         $scope.clearStatus()
         var now = new Date()
         $scope.status = 'Looking up purchase…'
         var purchasePromises = $scope.purchase.split(',').map(function (purchaseId) {
            return AzureAPI.purchase.get({
               id: purchaseId,
            }).$promise
         })

         if (purchasePromises.length > config.apiLimit) {
            throw (
               'not implemented: ' +
               purchasePromises.length +
               ' purchases ' +
               '(currently you can only list ' +
               config.apiLimit +
               ' at a time)'
            )
         }

         var orderQueryParams
         $q.all(purchasePromises)
            .then(function (purchases) {
               var oldestPurchaseArrival = now

               purchases.forEach(function (purchase) {
                  oldestPurchaseArrival = new Date(Math.min(oldestPurchaseArrival, new Date(purchase.arrived)))
               })

               orderQueryParams = {
                  'shipped-after': oldestPurchaseArrival.toISOString().split('T')[0],
                  status: 'shipped',
                  'packaged-product': $scope.packagedProduct,
               }

               $scope.status =
                  'Counting orders for ' +
                  orderQueryParams['packaged-product'] +
                  ' after ' +
                  orderQueryParams['shipped-after'] +
                  '…'

               return AzureAPI.order.count(orderQueryParams).$promise
            })
            .then(function (orderCountPromise) {
               $scope.status = 'Fetching ' + orderCountPromise.resource.count + ' orders…'

               function getOrder(start, limit) {
                  orderQueryParams.start = start
                  orderQueryParams.limit = limit
                  return AzureAPI.order.query(orderQueryParams).$promise
               }

               // Get all orders
               return util.getPagesUntilEnd(getOrder, 0, config.apiLimit)
            })
            // ---------------------------------------------
            // Fetch orderlines
            // ---------------------------------------------
            .then(function (orders) {
               $scope.status = 'Fetching order-lines associated ' + 'with the orders…'

               function queryOrderline(order) {
                  return AzureAPI['order-line'].query({
                     order: order.id,
                     'packaged-product': $scope.packagedProduct,
                  }).$promise
               }

               return util
                  .batchRequests(orders, queryOrderline, {
                     batchSize: _batchSize,
                     retry: true,
                  })
                  .then(function (orderLineGroups) {
                     orderLineGroups.forEach(function (orderLineGroup) {
                        var order = orders.find(function (order) {
                           return order.id === orderLineGroup[0].order
                        })
                        order.orderLines = orderLineGroup
                           .map(function (orderLine) {
                              return util.cleanItem(orderLine)
                           })
                           .filter(function (orderLine) {
                              // Only add orderlines that shipped (not those that were shorted)
                              return orderLine['quantity-shipped']
                           })
                     })
                     return orders
                  })
            })
            // ---------------------------------------------
            // Fetch customers
            // ---------------------------------------------
            .then(function (orders) {
               $scope.status = 'Fetching customers associated ' + 'with the orders…'

               function getPerson(customerView) {
                  return AzureAPI.person.get({
                     id: customerView.customerId,
                     inline: 'email',
                  }).$promise
               }

               var customerViews = []

               orders.forEach(function (order) {
                  var maybeExistingCustomerView = customerViews.find(function (customerView) {
                     return customerView.customerId === order.customer
                  })

                  if (maybeExistingCustomerView) {
                     maybeExistingCustomerView.orders.push(order)
                  } else {
                     customerViews.push({
                        customerId: order.customer,
                        orders: [order],
                     })
                  }
               })

               return util
                  .batchRequests(customerViews, getPerson, {
                     batchSize: _batchSize,
                     retry: true,
                  })
                  .then(function (customers) {
                     customers.forEach(function (customer) {
                        var customerView = customerViews.find(function (customerView) {
                           return customerView.customerId === customer.id
                        })
                        customerView.customer = customer
                     })
                     return customerViews
                  })
            })
            .then(function (customerViews) {
               $scope.customerViews = []

               customerViews.forEach(function (customerView) {
                  // There's no orderLine if there was no `quantity-shipped`, in which
                  // case we don't need to show these customers (since they never got the item(s)).
                  var orderlineCount = customerView.orders.reduce(function (count, order) {
                     return count + order.orderLines.length
                  }, 0)
                  if (orderlineCount) {
                     $scope.customerViews.push(customerView)
                  }
               })

               $scope.customerCount = $scope.customerViews.length

               $scope.status = 'Completed'
            })
            .catch(alert.catchNetwork('recall'))
      }

      //================================================================================
      // Init
      //================================================================================

      function init() {
         $scope.clearStatus()
         $scope.submitReportRequest()
      }

      init()
   }
})()
