;(function () {
   'use strict'

   angular.module('internalWebsiteApp').directive('bartenderHostsTab', Directive)

   function Directive() {
      return {
         restrict: 'E',
         templateUrl: 'modules/bartender/tabs/hosts/hosts.html',
         controller: ['AzureAPI', 'alert', Controller],
         controllerAs: 'hostTab',
      }
   }

   function Controller(AzureAPI, alert) {
      var hostTab = this

      // --- Get Hosts ----------------------------------------------------------
      hostTab.hosts = []

      function getHosts() {
         hostTab.hosts = AzureAPI['bartender-host'].query({limit: 250})
      }
      // ------------------------------------------------------------------------

      // --- Create New Host ----------------------------------------------------
      hostTab.toggleNewForm = function () {
         hostTab.showNewForm = !hostTab.showNewForm
      }

      hostTab.createHost = function () {
         var newHost = AzureAPI['bartender-host'].create(hostTab.newHost)
         newHost.$promise
            .then(function (resp) {
               hostTab.hosts.unshift(newHost)
               hostTab.clearNewHost()
            })
            .catch(alert.catchNetwork('Bartender Hosts'))
      }

      hostTab.clearNewHost = function () {
         hostTab.newHost = {}
         hostTab.showNewForm = false
      }
      // ------------------------------------------------------------------------

      // --- Manage Hosts -------------------------------------------------------
      hostTab.editView = {}
      var stashedHost = {}

      hostTab.removeHost = function (host, index) {
         host
            .$delete()
            .then(function (resp) {
               hostTab.hosts.splice(index, 1)
            })
            .catch(alert.catchNetwork('Bartender Hosts'))
      }

      hostTab.editHost = function (host, index) {
         stashedHost = angular.copy(host)
         hostTab.editView[index] = true
      }

      hostTab.cancelEditHost = function (index) {
         hostTab.hosts[index] = stashedHost
         clearEditHost(index)
      }

      hostTab.saveHost = function (host, index) {
         host
            .$save()
            .then(function (resp) {
               clearEditHost(index)
            })
            .catch(alert.catchNetwork('Bartender Hosts'))
      }

      function clearEditHost(index) {
         hostTab.editView[index] = false
         stashedHost = {}
      }
      // ------------------------------------------------------------------------

      // --- Initialization -----------------------------------------------------
      function init() {
         hostTab.clearNewHost()
         getHosts()
      }
      init()
      // ------------------------------------------------------------------------
   }
})()
