;(function () {
   'use strict'

   angular.module('internalWebsiteApp').controller('BartenderCtrl', Controller)

   function Controller(alert, $location, AzureAPI) {
      var bartender = this

      var params = $location.search()
      var activeTab = params.tab || 'config'
      if (!params.tab) {
         setTabParam('config')
      }

      bartender.loadTab = function (target) {
         activeTab = target
         setTabParam(target)
      }

      bartender.isActive = function (tab) {
         return activeTab === tab
      }

      function setTabParam(name) {
         $location.search({
            tab: name,
         })
      }
   }
})()
