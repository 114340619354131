'use strict'

/**
 * @ngdoc function
 * @name internalWebsiteApp.controller:SignInCtrl
 * @description
 * # SignInCtrl
 * Controller of the internalWebsiteApp
 */
angular.module('internalWebsiteApp').controller('SignInCtrl', [
   '$rootScope',
   '$scope',
   '$location',
   'alert',
   'AzureAPI',
   function ($rootScope, $scope, $location, alert, AzureAPI) {
      $scope.credentials = {
         username: '',
         password: '',
      }

      $scope.signIn = function () {
         if (!$scope.credentials.username) {
            alert.add('warning', 'Please enter your username or email and try again.')
         } else if (!$scope.credentials.password) {
            alert.add('warning', 'Please enter a password try again.')
         } else {
            AzureAPI.login($scope.credentials)
               .then(function (session) {
                  AzureAPI.person.get().$promise.then(function (person) {
                     if (person.permissions.indexOf('staff') > -1) {
                        alert.clear()
                        $rootScope.person = person
                        $location.path('/')
                     } else {
                        alert.add('danger', 'You must be a staff member to access this site.')
                        $scope.cancel()
                     }
                  })
               })
               .catch(function (data, status) {
                  alert.add('danger', 'Login incorrect. Please try again.')
               })
         }
      }

      $scope.cancel = function () {
         $scope.credentials = {
            username: '',
            password: '',
         }
         angular.element('#input-username').focus()
      }
   },
])
