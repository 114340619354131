;(function () {
   'use strict'
   angular
      .module('internalWebsiteApp')
      .directive('editCategory', function () {
         var directive = {
            restrict: 'E',
            templateUrl: 'modules/categories/categories-edit.html',
            scope: {
               categoryToEdit: '=node',
               successAction: '=success',
               cancelAction: '=cancel',
            },
            controller: 'EditCategoryCtrl',
         }
         return directive
      })

      .controller('EditCategoryCtrl', function ($scope, AzureAPI, Upload) {
         var backup = angular.copy($scope.categoryToEdit)
         $scope.saveCategory = function (valid) {
            if (!valid) {
               console.log('!valid')
               return
            }
            if (typeof $scope.categoryToEdit.keywords === 'string') {
               $scope.categoryToEdit.keywords = $scope.categoryToEdit.keywords.split(/\s*,\s*/)
            } else if (!$scope.categoryToEdit.keywords) {
               $scope.categoryToEdit.keywords = []
            }
            if ($scope.categoryToEdit.newimage instanceof Blob) {
               Upload.base64DataUrl($scope.categoryToEdit.newimage).then(function (dataurl) {
                  $scope.categoryToEdit.image = dataurl.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                  delete $scope.categoryToEdit.newimage
                  saveCategory($scope.categoryToEdit)
               })
            } else {
               saveCategory($scope.categoryToEdit)
            }
         }

         function saveCategory(category) {
            AzureAPI.category.save(category).$promise.then(function (resp) {
               console.log('saveCategory: ', resp)
               category.image = resp.image
               $scope.successAction(resp)
               $scope.categoryToEdit.displayEditCategory = false
            })
         }

         $scope.removeImage = function () {
            delete $scope.categoryToEdit.image
         }

         $scope.cancel = function () {
            $scope.categoryToEdit.name = backup.name
            $scope.categoryToEdit.keywords = backup.keywords
            $scope.categoryToEdit['short-name'] = backup['short-name']
            $scope.cancelAction($scope.categoryToEdit)
            $scope.categoryToEdit.displayEditCategory = false
         }
      })
})()
