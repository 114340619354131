'use strict'

/**
 * @ngdoc service
 * @name internalWebsiteApp.config
 * @description
 * # config
 * Factory in the internalWebsiteApp.
 */
angular.module('internalWebsiteApp').factory('config', function () {
   return {
      website: 'https://www.azurestandard.com',
      apiLimit: 250,
   }
})
