'use strict'

/**
 * @ngdoc service
 * @name internalWebsiteApp.alert
 * @description
 * # alert
 * Factory in the internalWebsiteApp.
 */
angular.module('internalWebsiteApp').factory('alert', function ($rootScope, ENV) {
   $rootScope.alerts = []

   var Alert = function (type, message) {
      this.type = type
      this.message = message
   }

   Alert.prototype.close = function () {
      var index = $rootScope.alerts.indexOf(this)
      $rootScope.alerts.splice(index, 1)
   }

   var add = function (type, message) {
      angular.element('body').animate(
         {
            scrollTop: 0,
         },
         'slow'
      )
      var alert = new Alert(type, message)
      $rootScope.alerts.push(alert)
      return alert
   }

   var clear = function () {
      $rootScope.alerts.length = 0
   }

   function missingPrimaryContact(error) {
      var dropId = error.data.message.match(/\d+/)
      var message = 'Drop D' + dropId + ' does not have a primary contact set. '
      message += 'This means that drops will not be displayed as expected. Please go to '
      message += '<a href="' + ENV.beehive + '/drops/' + dropId[0] + '/detail" target="_blank">Beehive</a> '
      message += 'to add a primary contact.'
      add('danger', message)
   }

   function catchNetwork(model) {
      return function (error) {
         console.error(error)
         if (error.data && error.data.message && error.data.message.indexOf('does not have a primary contact') >= 0) {
            missingPrimaryContact(error)
         } else {
            var status = error.statusText
            if (error.data && error.data.message) {
               status = error.data.message
            }
            var message = 'failed to ' + error.config.method + ' ' + model + ' (' + error.status + ': ' + status + ')'
            add('warning', message)
         }
      }
   }

   return {
      add: add,
      clear: clear,
      catchNetwork: catchNetwork,
   }
})
