;(function () {
   'use strict'

   angular
      .module('internalWebsiteApp')
      .controller('ModalSendMessage', function ($scope, $q, AzureAPI, selectedDropsToMessage, logisticsRootState) {
         var _localState = {
            selectedDropsToMessage: selectedDropsToMessage,
            // ----------------------------------------------------------------------------
            // Form data
            // ----------------------------------------------------------------------------
            tabParam: logisticsRootState.params.tab,
            hubspotTemplateOptions: [
               {
                  emailDisplayName: 'Delivery Schedule',
                  emailConfigName: 'HUBSPOT_DELIVERY_SCHEDULE_EMAIL_ID',
               },
               {
                  emailDisplayName: 'Delivery Schedule Delay',
                  emailConfigName: 'HUBSPOT_DELIVERY_SCHEDULE_DELAY_EMAIL_ID',
               },
               {
                  emailDisplayName: 'Driver Info',
                  emailConfigName: 'HUBSPOT_DRIVER_INFO_EMAIL_ID',
               },
            ],
            selectedHubSpotTemplate: undefined,
            reasonForDeliveryDelay: undefined,
            messageType: 'custom', // 'hubspot' || 'custom`
            messageIncludeCustomers: true,
            messageTestAddress: logisticsRootState.person.email,
            messageTestPhone: logisticsRootState.person.phone,
            messageSubject: undefined,
            messageBody: undefined,
            // ----------------------------------------------------------------------------
            // Sending state
            // ----------------------------------------------------------------------------
            initialProofSent: false,
            error: false,
            isSending: false,
            isValid: true,
            validationHubSpotTemplateErrorText: undefined,
            UiStatusText: undefined,
         }
         $scope.localState = _localState

         var testResendCount = 0
         var maxAllowedSmsMessageChars = 160
         var hubSpotDeliveryEmailNames = ['Delivery Schedule', 'Delivery Schedule Delay']

         //================================================================================
         // Internal functions
         //================================================================================

         function sendMessage(proofMode) {
            _localState.isSending = true
            _localState.UiStatusText = proofMode ? 'Sending test ' + smsOrEmail() : 'Sending ' + smsOrEmail()

            var options = {
               customers: _localState.messageIncludeCustomers,
               drop: selectedDropsToMessage
                  .map(function (drop) {
                     return drop.id
                  })
                  .join(','),
            }

            // Passing a `target` puts the message-sending in test mode (the message is only sent to the target).
            // TODO: This needs to be documented in the API Spec (from my searching it's not).
            if (proofMode) {
               if (_localState.messageType === 'sms') {
                  if (_localState.messageTestPhone) {
                     options.target = _localState.messageTestPhone
                  } else {
                     return preventTestMessageFromSending('You must provide a phone number for the test sms!')
                  }
               } else {
                  if (_localState.messageTestAddress) {
                     options.target = _localState.messageTestAddress
                  } else {
                     return preventTestMessageFromSending('You must provide an email recipient for the test email!')
                  }
               }
            }

            var endpointResource
            if (logisticsRootState.params.tab === 'drops') {
               options.name = logisticsRootState.route.name
               endpointResource = 'route'
            } else if (logisticsRootState.params.tab === 'trips') {
               options.id = logisticsRootState.params.trip
               endpointResource = 'trip'
            }

            if (_localState.messageType === 'sms') {
               if (_localState.messageBody.length > maxAllowedSmsMessageChars) {
                  _localState.isSending = false
                  _localState.error = true
                  _localState.UiStatusText = 'Max length for a text message is 160.'
                  return $q.reject()
               }
               options.sms = _localState.messageBody
            } else if (_localState.messageType === 'hubspot') {
               if (!_localState.selectedHubSpotTemplate) {
                  _localState.isSending = false
                  _localState.error = true
                  _localState.UiStatusText = 'You must select a HubSpot email template!'
                  return $q.reject()
               }
               options.emailConfigName = _localState.selectedHubSpotTemplate.emailConfigName
               if (_localState.selectedHubSpotTemplate.emailDisplayName === 'Delivery Schedule Delay') {
                  options.deliveryDelayReason = _localState.reasonForDeliveryDelay
               }
            } else {
               options.subject = _localState.messageSubject
               options.message = _localState.messageBody
            }

            return AzureAPI[endpointResource]
               .message(options, null)
               .$promise.then(function (response) {
                  _localState.error = false
                  if (proofMode && _localState.initialProofSent) {
                     testResendCount = testResendCount + 1
                     var timeOrTimes = testResendCount > 1 ? ' times' : ' time'
                     _localState.UiStatusText =
                        'Test ' + smsOrEmail() + ' re-sent (' + testResendCount + timeOrTimes + ')!'
                  } else if (proofMode) {
                     _localState.initialProofSent = true
                     _localState.UiStatusText = 'Test ' + smsOrEmail() + ' sent!'
                  }
                  if (response.hasOwnProperty('message')) {
                     _localState.UiStatusText = _localState.UiStatusText + ` ${response.message}`
                  }
               })
               .catch(function (response) {
                  _localState.error = true
                  if (response.data && response.data.message) {
                     _localState.UiStatusText = response.data.message
                  } else {
                     _localState.UiStatusText = response.statusText
                  }
               })
               .finally(function () {
                  _localState.isSending = false
               })
         }

         function preventTestMessageFromSending(uiStatusText) {
            _localState.isSending = false
            _localState.error = true
            _localState.UiStatusText = uiStatusText
            return $q.reject()
         }

         function smsOrEmail() {
            return _localState.messageType === 'sms' ? 'SMS' : 'email'
         }

         //================================================================================
         // Scope functions
         //================================================================================

         $scope.sendProof = function () {
            sendMessage(true)
         }

         $scope.clickedSendActual = function () {
            if (_localState.initialProofSent) {
               _localState.showConfirmSendActual = true
            }
         }

         $scope.sendActual = function () {
            if (_localState.initialProofSent) {
               _localState.showConfirmSendActual = false
               sendMessage()
               _localState.messageSent = true
            }
         }

         $scope.smsOrEmail = smsOrEmail

         $scope.maxAllowedSmsMessageChars = maxAllowedSmsMessageChars

         $scope.maybePreventSmsMessageInput = function (e) {
            if (
               e.key !== 'Backspace' &&
               _localState.messageBody &&
               _localState.messageBody.length >= maxAllowedSmsMessageChars
            ) {
               e.preventDefault()
            }
         }

         $scope.onHubSpotTemplateChange = function () {
            if (_localState.selectedHubSpotTemplate.emailDisplayName === 'Driver Info') {
               _localState.messageIncludeCustomers = false
            }
            // validate based on _localState.selectedHubSpotTemplate
            _localState.isValid = true
            if (
               logisticsRootState.params.tab === 'trips' &&
               hubSpotDeliveryEmailNames.includes(_localState.selectedHubSpotTemplate.emailDisplayName)
            ) {
               // validate drop.finalizedDelivery and drop.finalizedDeliveryFlag
               _localState.isValid = selectedDropsToMessage.every((drop) => {
                  return drop.finalizedDelivery || drop.finalizedDeliveryFlag
               })
               if (!_localState.isValid) {
                  _localState.validationHubSpotTemplateErrorText =
                     'Either the Finalized Delivery Date or Flag needs to be set.'
               }
            }
         }
      })
})()
