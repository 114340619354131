'use strict'

/**
 * @ngdoc function
 * @name internalWebsiteApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the internalWebsiteApp
 */
angular.module('internalWebsiteApp').controller('HomeCtrl', function ($rootScope, $scope, Listings) {
   $rootScope.apps = Listings
})
