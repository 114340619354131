/**
 * List of Applications.
 * The listings here are used to generate the landing page and drop down
 * on the title bar.
 */

;(function () {
   'use strict'

   angular.module('internalWebsiteApp').provider('Listings', function () {
      var listings = this
      listings.apps = [
         {
            name: 'Logistics',
            url: '#/logistics',
            icon: 'glyphicon-calendar',
            desc: ['Manage routes, trips, and stops', 'Message drop coordinators and customers'],
            templateUrl: 'modules/logistics/views/logistics.html',
            controller: 'LogisticsCtrl',
            reloadOnSearch: false,
         },
         {
            name: 'Category Manager',
            url: '#/categories',
            icon: 'glyphicon-tags',
            desc: ['Manage the product-category tree', 'Assign products to categories'],
            templateUrl: 'modules/categories/categories.html',
            controller: 'CategoriesCtrl',
         },
         {
            name: 'Recall',
            url: '#/recall',
            icon: 'glyphicon-alert',
            desc: ['Recall products by Product Code', 'Recall products by Purchase #'],
            templateUrl: 'modules/recall/recall.html',
            controller: 'RecallCtrl',
         },
         {
            name: 'Product Audit',
            url: '#/products/audit/size',
            icon: 'glyphicon-thumbs-up',
            desc: ['Audit product size listings for as-low-as price display'],
            templateUrl: 'modules/price-audit/product-audit-size.html',
            controller: 'ProductAuditSizeCtrl',
            controllerAs: 'pas',
         },
         {
            name: 'Telephone',
            url: '#/telephone',
            icon: 'glyphicon-earphone',
            desc: ['Lookup customers by phone number'],
            templateUrl: 'modules/telephone/telephone.html',
            controller: 'TelephoneCtrl',
         },
         {
            name: 'Drop Membership',
            url: '#/drop-membership',
            icon: 'glyphicon-knight',
            desc: ['Manage requests to join drops that need approval'],
            templateUrl: 'modules/drop-membership/drop-membership.html',
            controller: 'DropMembershipCtrl',
            controllerAs: 'dm',
            reloadOnSearch: false,
         },
         {
            name: 'BarTender Configuration',
            url: '#/bartender',
            icon: 'glyphicon-print',
            desc: ['Manage print configurations', 'Add or remove print templates', 'Manage BarTender hosts'],
            templateUrl: 'modules/bartender/bartender.html',
            controller: 'BartenderCtrl',
            controllerAs: 'bartender',
            reloadOnSearch: false,
         },
      ]

      this.$get = function () {
         return listings.apps
      }
   })
})()
