;(function (angular) {
   'use strict'

   angular.module('internalWebsiteApp').factory('alertService', alertService)

   function alertService($rootScope, $timeout) {
      $rootScope.alertServiceAlerts = []
      var queuedAlerts = []

      //================================================================================
      // USAGE
      //================================================================================

      // Exposed Alerts:
      // - core -
      // alertService.success()
      // alertService.info()
      // alertService.warning()
      // alertService.error()
      // - shorthands -
      // alertService.successMessage()
      // alertService.infoMessage()
      // alertService.warningMessage()
      // alertService.errorMessage()
      //
      // Note: the type + "message" alerts are shorthands:
      // Reasoning:
      // Since code like this was so common: alertService.add('danger', error.message);
      // And this is unnecessarily verbose:
      // alertService.error({
      //     message: error.message
      // });
      // We have this:
      // alertService.error(error);
      //
      // Description:
      //    Adds an alert to the $rootScope.alertServiceAlerts array.
      //    Alerts in this array are rendered in index.html
      // Parameters:
      //    options.type: String. Class "Alert--{{type}}" will be added to the alert.
      //    options.message: String. Message to display.
      //    options.autoClose: Bool. Whether to auto-close the alert.
      //    options.closeBtn: Bool. Whether to show the close button.
      //    options.duration: Number. If options.autoClose is true, alert will auto-close after this many milliseconds.
      //    options.fullscreenOverlay: Boolean. If true, a fullscreen overlay will accompany the alert (useful for ensuring user's notice it).
      //    options.queue: Boolean. If true, the alert will be queued. Queued alerts can be added later using addQueued().

      //================================================================================
      // INTERNAL
      //================================================================================

      function add(options) {
         if (!options.message) {
            console.error('You must pass a message to alertService.' + options.type)
            return
         }
         if (options.queue) {
            queuedAlerts.push(
               Object.assign({}, options, {
                  queue: false,
               })
            )
            return
         }
         var alert = {
            type: options.type,
            msg: options.message,
            autoClose: options.autoClose,
            closeBtn: options.closeBtn,
            fullscreenOverlay: options.fullscreenOverlay,
            close: function () {
               var alertIndex = $rootScope.alertServiceAlerts.indexOf(this)
               if (alertIndex >= 0) {
                  return $rootScope.alertServiceAlerts.splice(alertIndex, 1)
               }
            },
         }
         if (options.autoClose) {
            $timeout(function () {
               alert.close()
            }, options.duration)
         }
         return $rootScope.alertServiceAlerts.push(alert)
      }

      //================================================================================
      // PRIMARY ALERTS
      //================================================================================

      var defaultOptions = {
         autoClose: false,
         duration: 3000,
         closeBtn: true,
         queue: false,
      }

      // Sucess - Green
      function success(options) {
         var successDefaults = {
            type: 'success',
            autoClose: true,
            closeBtn: false,
         }
         return add(Object.assign({}, defaultOptions, successDefaults, options))
      }

      // Info - Blue
      function info(options) {
         var infoDefaults = {
            type: 'info',
         }
         return add(Object.assign({}, defaultOptions, infoDefaults, options))
      }

      // Warning - Yellow
      function warning(options) {
         var warningDefaults = {
            type: 'warning',
         }
         return add(Object.assign({}, defaultOptions, warningDefaults, options))
      }

      // Error - Red
      function error(options) {
         // A falsy message becomes the default message
         if (options && !options.message) {
            delete options.message
         }
         var errorDefaults = {
            type: 'error',
            message: 'There was a problem. Please try again.',
         }
         return add(Object.assign({}, defaultOptions, errorDefaults, options))
      }

      //================================================================================
      // SHORTHAND ALERTS
      //================================================================================

      function successMessage(message) {
         return success({
            message: message,
         })
      }

      function infoMessage(message) {
         return info({
            message: message,
         })
      }

      function warningMessage(message) {
         return warning({
            message: message,
         })
      }

      function errorMessage(message) {
         return error({
            message: message,
         })
      }

      //================================================================================

      function clear() {
         $rootScope.alertServiceAlerts = []
      }

      function addQueued() {
         var queuedAlertCount = queuedAlerts.length
         for (var i = 0; i < queuedAlertCount; i++) {
            add(queuedAlerts.shift())
         }
      }

      //================================================================================

      return {
         clear: clear,
         addQueued: addQueued,
         // alert Types
         success: success,
         info: info,
         warning: warning,
         error: error,
         // Shorthands (for only passing the message, no customization)
         successMessage: successMessage,
         infoMessage: infoMessage,
         warningMessage: warningMessage,
         errorMessage: errorMessage,
      }
   }
})(angular)
