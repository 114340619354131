;(function (angular) {
   'use strict'

   angular.module('internalWebsiteApp').directive('asBindHtmlCompile', bindHtmlCompile)

   function bindHtmlCompile($compile) {
      return {
         restrict: 'A',
         link: bindHtmlCompileLink,
      }

      function bindHtmlCompileLink(scope, element, attrs) {
         scope.$watch('content', function () {
            if (attrs.asBindHtmlCompile) {
               element.html(attrs.asBindHtmlCompile)
               $compile(element.contents())(scope)
            } else {
               element.html('')
            }
         })
      }
   }
})(angular)
