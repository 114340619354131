;(function () {
   'use strict'

   angular.module('internalWebsiteApp').directive('navbar', function () {
      function Controller($rootScope, $location, Listings, AzureAPI) {
         var navbar = this
         navbar.apps = Listings

         $rootScope.$watch('person.id', function () {
            navbar.person = $rootScope.person
         })

         navbar.signOut = function () {
            AzureAPI.logout().then(function () {
               $rootScope.person = null
               $location.path('/signin')
            })
         }
      }

      var directive = {
         restrict: 'E',
         templateUrl: 'modules/navbar/navbar.html',
         scope: {
            title: '=',
         },
         controller: ['$rootScope', '$location', 'Listings', 'AzureAPI', Controller],
         controllerAs: 'navbar',
         bindToController: true,
      }

      return directive
   })
})()
