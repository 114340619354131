;(function (angular, Sentry) {
   'use strict'

   /**
    * @ngdoc overview
    * @name internalWebsiteApp
    * @description
    * # internalWebsiteApp
    *
    * Main module of the application.
    */
   angular
      .module('internalWebsiteApp', [
         'ngAnimate',
         'ngAria',
         'ngCookies',
         'ngResource',
         'ngRoute',
         'ngSanitize',
         'ngTouch',
         'ui.bootstrap',
         'azureProviders',
         'angularSpinner',
         'ngFileUpload',
         'ngPromiseExtras',
         'ui.mask',
         'as.sortable',
         'datetime',
         'ngContextMenu',
         'azure.exceptionHandler',
      ])
      .config(function ($routeProvider, $locationProvider, AzureAPIProvider, ENV, ListingsProvider) {
         $locationProvider.hashPrefix('')

         AzureAPIProvider.setUrl(ENV.apiEndpoint)

         ListingsProvider.apps.forEach(function (app) {
            $routeProvider.when(app.url.substr(1), app)
         })

         $routeProvider
            .when('/', {
               templateUrl: 'modules/home/home.html',
               controller: 'HomeCtrl',
               name: 'Home',
            })
            .when('/signin', {
               templateUrl: 'modules/auth/signin.html',
               controller: 'SignInCtrl',
            })
            .otherwise({
               redirectTo: '/',
            })
      })
      .run(function ($rootScope, $location, $route, $window, AzureAPI, alert, util) {
         // For programatically removing an actively focused element
         $rootScope.blur = function () {
            if (document.activeElement && document.activeElement.blur) {
               document.activeElement.blur()
            }
         }

         //================================================================================

         $rootScope.maybeShowMinSupportedWidthWarning = $window.localStorage.doNotShowMinSupportedWidthWarning
            ? false
            : true

         $rootScope.closeMinSupportedWidthWarning = function () {
            $rootScope.maybeShowMinSupportedWidthWarning = false
            $window.localStorage.doNotShowMinSupportedWidthWarning = true
         }

         //================================================================================

         $rootScope.$on('$routeChangeStart', function (event, next) {
            if (!$rootScope.person) {
               if (next.originalPath !== '/signin') {
                  event.preventDefault()
               }
               var session = AzureAPI.session.get()
               session.$promise.then(function (session) {
                  if (session.person) {
                     AzureAPI.person.get().$promise.then(function (person) {
                        if (person.permissions.indexOf('staff') > -1) {
                           $rootScope.person = person
                           if (next.originalPath === '/signin') {
                              $location.path('/')
                           } else {
                              $location.path(next.originalPath)
                           }
                           $route.reload()
                        } else {
                           alert.add('danger', 'You must be a staff member to access this site.')
                           $location.path('/signin')
                        }
                     })
                  } else {
                     $location.path('/signin')
                  }
               })
            }
            // Is signed-in user
            else {
               setSentryUser()
            }

            $rootScope.$on('$routeChangeSuccess', function () {
               var routeName = $route.current.name
               $rootScope.title = routeName

               var currentAppSlug = util.makeCamelCase(routeName)
               $rootScope.currentAppSlug = 'currentAppSlug-' + currentAppSlug
            })
         })

         $rootScope.$watch('person.id', setSentryUser)

         function setSentryUser() {
            if (Sentry) {
               Sentry.configureScope(function (scope) {
                  scope.setUser($rootScope.person)
               })
            }
         }
      })
})(angular, window.Sentry)
