;(function (angular) {
   'use strict'

   /**
    * @ngdoc filter
    * @name internalWebsiteApp.filter:convertPostalStateFormat
    * @description
    * # convertPostalStateFormat
    * Convert State (between abbreviated and full)
    * Adapted from: https://gist.github.com/CalebGrove/c285a9510948b633aa47
    *
    * USAGE:
    * convertPostalStateFormat('ny');
    * --> 'new york'
    * convertPostalStateFormat('New York');
    * --> 'NY'
    */

   angular.module('internalWebsiteApp').filter('convertPostalStateFormat', convertPostalStateFormatFilter)

   function convertPostalStateFormatFilter() {
      return function (stateStringToConvert) {
         if (!stateStringToConvert) {
            return
         }
         var states = [
            ['arizona', 'AZ'],
            ['alabama', 'AL'],
            ['alaska', 'AK'],
            ['arkansas', 'AR'],
            ['california', 'CA'],
            ['colorado', 'CO'],
            ['connecticut', 'CT'],
            ['delaware', 'DE'],
            ['florida', 'FL'],
            ['georgia', 'GA'],
            ['hawaii', 'HI'],
            ['idaho', 'ID'],
            ['illinois', 'IL'],
            ['indiana', 'IN'],
            ['iowa', 'IA'],
            ['kansas', 'KS'],
            ['kentucky', 'KY'],
            ['louisiana', 'LA'],
            ['maine', 'ME'],
            ['maryland', 'MD'],
            ['massachusetts', 'MA'],
            ['michigan', 'MI'],
            ['minnesota', 'MN'],
            ['mississippi', 'MS'],
            ['missouri', 'MO'],
            ['montana', 'MT'],
            ['nebraska', 'NE'],
            ['nevada', 'NV'],
            ['new hampshire', 'NH'],
            ['new jersey', 'NJ'],
            ['new mexico', 'NM'],
            ['new york', 'NY'],
            ['north carolina', 'NC'],
            ['north dakota', 'ND'],
            ['ohio', 'OH'],
            ['oklahoma', 'OK'],
            ['oregon', 'OR'],
            ['pennsylvania', 'PA'],
            ['rhode island', 'RI'],
            ['south carolina', 'SC'],
            ['south dakota', 'SD'],
            ['tennessee', 'TN'],
            ['texas', 'TX'],
            ['utah', 'UT'],
            ['vermont', 'VT'],
            ['virginia', 'VA'],
            ['washington', 'WA'],
            ['west virginia', 'WV'],
            ['wisconsin', 'WI'],
            ['wyoming', 'WY'],
         ]

         var matchedState
         var stringToMatch
         if (stateStringToConvert.length > 2) {
            // from full to short
            stringToMatch = stateStringToConvert.toLowerCase()
            matchedState = states.find(function (state) {
               return state[0] === stringToMatch
            })
            if (matchedState) {
               return matchedState[1]
            }
         } else if (stateStringToConvert.length === 2) {
            // from short to full
            stringToMatch = stateStringToConvert.toUpperCase()
            matchedState = states.find(function (state) {
               return state[1] === stringToMatch
            })
            if (matchedState) {
               return matchedState[0]
            }
         }

         // no match, return original string
         return stateStringToConvert
      }
   }
})(angular)
