;(function () {
   'use strict'

   angular.module('internalWebsiteApp').directive('bartenderPrintConfigTab', Directive)

   function Directive() {
      return {
         restrict: 'E',
         templateUrl: 'modules/bartender/tabs/printconfig/printconfig.html',
         controller: ['AzureAPI', 'alert', Controller],
         controllerAs: 'configTab',
      }
   }

   function Controller(AzureAPI, alert) {
      var configTab = this

      // --- Get Data ----------------------------------------------------------

      configTab.configs = []
      configTab.printers = []
      configTab.templates = []
      configTab.hosts = []

      function getConfigs() {
         configTab.configs = AzureAPI['bartender-print-configuration'].query({limit: 250})
         configTab.configs.$promise.catch(alert.catchNetwork('Print Configurations'))
      }

      function getPrinters() {
         var printerOptions = {
            limit: 250,
            delivery: 'bartender',
            format: 'bartender',
         }
         configTab.printers = AzureAPI.printer.query(printerOptions)
         configTab.printers.$promise.catch(alert.catchNetwork('Printers'))
      }

      function getTemplates() {
         configTab.templates = AzureAPI['bartender-template'].query({limit: 250})
         configTab.templates.$promise.catch(alert.catchNetwork('Templates'))
      }

      function getHosts() {
         configTab.hosts = AzureAPI['bartender-host'].query({limit: 250})
         configTab.hosts.$promise.catch(alert.catchNetwork('Hosts'))
      }

      // --- Process Data -------------------------------------------------------

      configTab.getPrinter = function (id) {
         return findItemById('printers', id)
      }

      configTab.getTemplate = function (id) {
         return findItemById('templates', id)
      }

      configTab.getHost = function (code) {
         return findItemById('hosts', code, 'code')
      }

      function findItemById(list, id, identifier) {
         var rtn = {}
         var list = configTab[list]
         identifier = identifier || 'id'
         for (var i = 0; i < list.length; i++) {
            if (list[i][identifier] === id) {
               rtn = list[i]
               break
            }
         }
         return rtn
      }

      // --- Create New Config --------------------------------------------------

      configTab.newConfig = {}

      configTab.toggleNewForm = function () {
         configTab.showNewForm = !configTab.showNewForm
      }

      configTab.createConfig = function () {
         var newConfig = AzureAPI['bartender-print-configuration'].create(configTab.newConfig)
         newConfig.$promise
            .then(function () {
               configTab.configs.unshift(newConfig)
               console.log('createConfig: ', newConfig)
               configTab.clearNewConfig()
            })
            .catch(alert.catchNetwork('Bartender Print Configuration'))
      }

      configTab.clearNewConfig = function () {
         configTab.newConfig = {}
         configTab.showNewForm = false
      }

      // --- Manage Configs -----------------------------------------------------

      configTab.editView = {}
      var stashedConfig = {}

      configTab.sendTestPrint = function (config) {
         var options = {
            id: config.id,
            stock: configTab.testStockId,
         }
         var testPrint = AzureAPI['bartender-print-configuration'].testPrint(options)
         testPrint.$promise
            .then(function () {
               alert.add('success', 'Your test print request has been sent.')
            })
            .catch(alert.catchNetwork('Test Print'))
      }

      configTab.removeConfig = function (config, index) {
         config
            .$delete()
            .then(function () {
               configTab.configs.splice(index, 1)
            })
            .catch(alert.catchNetwork('Print Configurations'))
      }

      configTab.editConfig = function (config) {
         stashedConfig = angular.copy(config)
         configTab.editView[config.id] = true
      }

      configTab.cancelEditConfig = function (config, index) {
         configTab.configs[index] = stashedConfig
         clearEditConfig(config.id)
      }

      configTab.saveConfig = function (config) {
         config
            .$save()
            .then(function () {
               clearEditConfig(config.id)
            })
            .catch(alert.catchNetwork('Print Configurations'))
      }

      function clearEditConfig(configId) {
         configTab.editView[configId] = false
         stashedConfig = {}
      }

      // --- Initialization -----------------------------------------------------

      function init() {
         configTab.clearNewConfig()
         getHosts()
         getPrinters()
         getTemplates()
         getConfigs()
      }
      init()
   }
})()
