;(function () {
   'use strict'

   angular.module('internalWebsiteApp').factory('slug', function () {
      function createSlug(str) {
         // converts the following characters to spaces: ,.-()[]{}
         // remove whitespace from the beginning and end of the str
         // convert whitespace to hyphens
         if (str) {
            return str
               .toLowerCase()
               .replace(/[,\.\-\(\)\[\]\{\}}]/g, ' ')
               .trim()
               .replace(/\s+/g, '-')
         } else {
            return ''
         }
      }
      return {
         create: createSlug,
      }
   })
})()
